<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-center>
      <v-flex xs12 sm10 pb-10>
        <v-layout wrap>
          <v-flex xs12 sm9 md10 text-left pt-10 pb-2>
            <span
              style="color: #000000; font-family: poppinsbold; font-size: 25px"
              >Exam Details</span
            >
          </v-flex>
          <v-flex xs12 sm3 md2 text-right pt-10 pb-2>
                <v-select
                  dense
                  :items="items"
                  v-model="status"
                  label="Status"
                  outlined
                  item-text="name"
                  item-value="value"
                ></v-select>
              </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs12 sm12 pa-2 v-for="(item, i) in exam" :key="i">
            <v-card outlined>
              <v-layout wrap justify-start pa-2>
                <v-flex xs12 sm6 align-self-center>
                  <span style="font-size: 16px; font-family: poppinssemibold">{{
                    item.examAttendedCourseId.courseName
                  }}</span>
                </v-flex>
                <v-flex xs4 sm2>
                  <span
                    style="
                      font-size: 14px;
                      color: #5e5f5f;
                      font-family: poppinsregular;
                    "
                    >MCQ</span
                  >
                  <br />
                  <span
                    style="font-family: poppinsmedium; color: #3cb043"
                    v-if="item.isMcqCompleted"
                  >
                    <v-icon color="green">mdi-check-bold</v-icon></span
                  >
                  <span
                    style="font-family: poppinsmedium; color: #3cb043"
                    v-else
                  >
                    <v-icon color="red">mdi-close-thick</v-icon></span
                  >
                </v-flex>
                <v-flex xs4 sm2 v-if="item.isMcqCompleted">
                  <span
                    style="
                      font-size: 14px;
                      color: #5e5f5f;
                      font-family: poppinsregular;
                    "
                    >True/False</span
                  >
                  <br />
                  <span
                    style="font-family: poppinsmedium; color: #3cb043"
                    v-if="item.isTrueOrFalseCompleted"
                  >
                    <v-icon color="green">mdi-check-bold</v-icon></span
                  >
                  <span
                    style="font-family: poppinsmedium; color: #3cb043"
                    v-else
                  >
                    <v-icon color="red">mdi-close-thick</v-icon></span
                  >
                </v-flex>
                <v-flex xs4 sm2 v-if="item.isTrueOrFalseCompleted">
                  <span
                    style="
                      font-size: 14px;
                      color: #5e5f5f;
                      font-family: poppinsregular;
                    "
                    >Essay</span
                  >
                  <br />
                  <span
                    style="
                      font-family: poppinsmedium;
                      color: #3cb043;
                    "
                    v-if="item.isEssayCompleted && item.isEvaluationCompleted"
                  >
                    {{ item.markAttainedFromEassy }}
                  </span>
                  <span
                    style="
                      font-family: poppinsmedium;
                      color: #3cb043;
                    "
                    v-if="item.isEssayCompleted==false && item.isEvaluationCompleted==false"
                  >
                  <v-icon color="red">mdi-close-thick</v-icon>
                  </span>
                  <span
                    style="font-family: poppinsmedium; color:red ;
                      font-size: 14px;
                    "
                     v-if="item.isEssayCompleted && item.isEvaluationCompleted==false"
                  >
                    Pending for evaluation</span
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center pt-2>
          <v-flex xs12>
            <div class="text-center pb-5" v-if="pages > 1" >
              <v-pagination :length="pages" :total-visible="7" v-model="currentPage" color="green" circle></v-pagination>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      tab: null,
      guestid: "",
      appLoading: false,
      ServerError: false,
      disabled: false,
      exam: [],
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 12,
      status:'',
      items: [
        { name: "All", value: "" },
        { name: "Passed", value: "Pass" },
        { name: "Failed", value: "Fail" },
      ],
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
    status(){
        this.getData()
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/user/all/exam/list",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data:{
            limit: this.limit,
          page: this.currentPage,
          status:this.status

        },
      })
        .then((response) => {
          this.appLoading = false;
          this.exam = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped></style>